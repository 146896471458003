import { IOrder } from 'src/_models/order.model'
import { useUserStore } from 'src/_store/user.store'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useEffect, useState } from 'react'
import OrderSignatureGuaranteeFileView from 'src/components/order/order-signature-guarantee-file-view'

const OrderSignatureGuaranteeView = ({
  title,
  description,
  order,
  type,
  orderGuaranteeText,
  orderGuaranteeMediaKey,
}: {
  title: string
  description: string
  order: IOrder
  type: 'BUYER' | 'SUPPLIER' | 'SUPPLIER_DELIVERY_CONFIRMATION' | 'SUPPLIER_PRODUCT_CONFIRMATION'
  orderGuaranteeText: string | null
  orderGuaranteeMediaKey?: string
}) => {
  const { user } = useUserStore((state) => ({ user: state.user! }))
  const valueExists = orderGuaranteeText || orderGuaranteeMediaKey

  const textForWaitingForOtherUser = () => {
    if (user.userType === type) {
      return ''
    }
    if (
      user.userType === 'BUYER' &&
      (type === 'SUPPLIER_DELIVERY_CONFIRMATION' || type === 'SUPPLIER_PRODUCT_CONFIRMATION')
    ) {
      return ''
    }
    if (
      user.userType === 'BUYER' &&
      (order.status === 'BUYER_ORDER_GUARANTEED' ||
        order.status === 'PRODUCT_SHIPPED' ||
        order.status === 'DELIVERY_CONFIRMATION' ||
        order.status === 'PRODUCT_CONFIRMATION')
    ) {
      return 'Waiting for Supplier to upload the signature'
    }

    return 'Waiting for Buyer to upload the signature'
  }

  if (valueExists) {
    return (
      <div className="mb-[10px] flex items-center justify-between pl-[0px] align-middle">
        <div className="mb-[10px] w-[400px]">
          <div className="text-sm font-bold 2xl:text-lg">{title}</div>
          <div className="text-sm 2xl:text-base">{description}</div>
        </div>
        {orderGuaranteeText && (
          <div className="flex h-[100px] w-[260px] items-center justify-center gap-[10px] rounded-[10px] bg-[#F2F2F2]">
            {orderGuaranteeText}
          </div>
        )}
        {orderGuaranteeMediaKey && (
          <div className="relative h-[100px] w-[260px] items-center justify-center gap-[10px] rounded-[10px] bg-[#F2F2F2]">
            <OrderSignatureGuaranteeFileView fileType={type} orderId={order.id} />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className="mb-[10px] flex items-center justify-between pl-[0px] align-middle">
      <div className="mb-[10px] w-[400px]">
        <div className="text-sm font-bold 2xl:text-lg">{title}</div>
        <div className="text-sm 2xl:text-base">{description}</div>
      </div>
      <div className="flex h-[100px] w-[260px] gap-[10px] rounded-[10px] bg-[#F2F2F2] items-center justify-center p-2.5 text-center">
        {textForWaitingForOtherUser()}
      </div>
    </div>
  )
}

export default OrderSignatureGuaranteeView
