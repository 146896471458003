import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Stepper } from 'src/components/ui/stepper'
import UserDetails from 'src/pages/auth/signup/user-details'
import VerificationSignUp from 'src/pages/auth/signup/verification'
import AccountSetupSignUp from 'src/pages/auth/signup/account-setup'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IUser } from 'src/_models/user.model'
import DocumentUpload from 'src/pages/auth/signup/document-upload'
import { useUserStore } from 'src/_store/user.store'
import mixpanel from 'mixpanel-browser'

export default function SignUpPage() {
  const navigate = useNavigate()
  const { t } = useTranslation('translation')
  const [queryParameters] = useSearchParams()
  const { setUser } = useUserStore((state) => {
    return {
      setUser: state.setUser,
    }
  })
  const parseStep = () => {
    try {
      return parseInt(queryParameters.get('step') || '0')
    } catch (e) {
      return 0
    }
  }
  const [step, setStep] = useState<number>(parseStep())

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ['profile'],
    queryFn: (): Promise<AxiosResponse<IUser>> => {
      const token = localStorage.getItem('token')
      return axios.get('/api/auth/profile-onboarding', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
    retryOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    enabled: step !== 0,
  })

  useEffect(() => {
    // if (step >= 1) {
    refetch()
    // }
  }, [step])

  useEffect(() => {
    if (isError && step >= 1) {
      navigate('/auth/login')
      return
    }
    if (!data?.data) {
      return
    }
    setUser(data.data)
    if (data.data.step === 'OTP_VERIFICATION') {
      setStep(1)
      navigate(`/auth/signup?role=${data.data.userType}&step=${1}`)
    } else if (data.data.step === 'ACCOUNT_SETUP') {
      setStep(2)
      navigate(`/auth/signup?role=${data.data.userType}&step=${2}`)
    } else if (data.data.step === 'DOCUMENT_UPLOAD') {
      setStep(3)
      navigate(`/auth/signup?role=${data.data.userType}&step=${3}`)
    } else {
      navigate('/')
    }
  }, [data, isError])

  let steps = []
  if (queryParameters.has('role') && queryParameters.get('role') === 'SUPPLIER') {
    steps = [
      { title: t('supplierDetails.heading') },
      { title: t('otpVerify.heading') },
      { title: t('accountSetup.heading') },
      { title: t('documentUpload.heading') },
    ]
  } else {
    steps = [
      { title: t('buyerDetails.heading') },
      { title: t('otpVerify.heading') },
      { title: t('accountSetup.heading') },
      { title: t('documentUpload.heading') },
    ]
  }

  if (isLoading && step > 0) {
    return <h1>Loading...</h1>
  }

  return (
    <div className="flex h-screen w-full flex-col items-center pl-[62px] pr-[83px] pt-10">
      <div className="customStepper flex w-full flex-col">
        <Stepper steps={steps} currentStep={step} />
        <div
          style={{ minHeight: 'calc(100vh - 198px)', maxHeight: 'calc(100vh - 198px)' }}
          className="w-full overflow-scroll rounded-[20px] bg-white px-10 py-[60px]"
        >
          <div className="flex h-full flex-col justify-between">
            {step === 0 && (
              <UserDetails
                goNext={() => {
                  setStep(1)
                  mixpanel.track('User Onboarding', {
                    step: 'OTP_VERIFICATION',
                    role: queryParameters.get('role'),
                  })
                  refetch()
                }}
              />
            )}
            {step === 1 && (
              <VerificationSignUp
                goNext={() => {
                  setStep(2)
                  mixpanel.track('User Onboarding', {
                    step: 'ACCOUNT_SETUP',
                    role: queryParameters.get('role'),
                  })
                }}
              />
            )}
            {step === 2 && (
              <AccountSetupSignUp
                goNext={() => {
                  setStep(3)
                  mixpanel.track('User Onboarding', {
                    step: 'DOCUMENT_UPLOAD',
                    role: queryParameters.get('role'),
                  })
                }}
              />
            )}
            {step === 3 && (
              <DocumentUpload
                goPrev={() => {
                  setStep(2)
                  mixpanel.track('User Onboarding', {
                    step: 'ACCOUNT_SETUP',
                    role: queryParameters.get('role'),
                  })
                }}
                goNext={() => {
                  navigate('/')
                  mixpanel.track('User Onboarding', {
                    step: 'COMPLETE',
                    role: queryParameters.get('role'),
                  })
                }}
              />
            )}
            {/*{step === 3 && <UserDetails />}*/}
          </div>
        </div>
      </div>
    </div>
  )
}
