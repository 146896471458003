import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'src/_store/user.store'
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar'
import { Menubar, MenubarContent, MenubarItem, MenubarMenu, MenubarTrigger } from 'src/components/ui/menubar'
import { useNavigate } from 'react-router-dom'
import { cn } from 'src/lib/utils'

export type IHeaderLink = {
  title: string
  navigationUrl: string
  children?: IHeaderLink[]
}

interface IProps {
  leftNode?: ReactNode
}
export function Header(props: IProps) {
  const { t } = useTranslation('translation')
  const { user, setUser } = useUserStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }))
  const navigate = useNavigate()

  const logOut = () => {
    localStorage.removeItem('token')
    setUser(null)
    navigate('/auth/login')
  }

  const headerLinks: IHeaderLink[] =
    user?.userType === 'SUPPLIER'
      ? [
          {
            title: t('header.myDashboard'),
            navigationUrl: '/',
            children: [],
          },
          {
            title: t('header.fuelTrading'),
            navigationUrl: '/supplier/fuel-trading',
            children: [
              {
                title: t('header.myDashboard'),
                navigationUrl: '/supplier/fuel-trading',
              },
              {
                title: t('header.myWatchlist'),
                navigationUrl: '/my-watchlist',
              },
              {
                title: t('header.liveAuctions'),
                navigationUrl: '/live-auctions',
              },
              {
                title: t('header.myAuctions'),
                navigationUrl: '/supplier/my-auctions',
              },
              {
                title: t('header.ordersInProgress'),
                navigationUrl: '/fuel-trading/orders-in-progress',
              },
              {
                title: t('header.completedOrders'),
                navigationUrl: '/fuel-trading/order-completed',
              },
              {
                title: t('header.transactionHistory'),
                navigationUrl: '/fuel-trading/transaction-history',
              },
              {
                title: t('header.pastAuctions'),
                navigationUrl: '/fuel-trading/past-auctions',
              },
            ],
          },
          // {
          //   title: t('header.greenTracing'),
          //   navigationUrl: '/green-tracing',
          //   children: [
          //     {
          //       title: t('header.myDashboard'),
          //       navigationUrl: '/green-tracing',
          //     },
          //     {
          //       title: t('header.myWatchlist'),
          //       navigationUrl: '/green-tracing/my-watchlist',
          //     },
          //     // {
          //     //   title: t('header.allProjects'),
          //     //   navigationUrl: '',
          //     // }
          //   ],
          // },
          // {
          //   title: t('header.recTrading'),
          //   navigationUrl: '/rec-trading',
          //   children: [
          //     {
          //       title: t('header.myDashboardRec'),
          //       navigationUrl: '/rec-trading',
          //     },
          //     {
          //       title: t('header.myWatchlistRec'),
          //       navigationUrl: '/rec-trading/my-watchlist',
          //     },
          //     {
          //       title: t('header.liveRecProjectsRec'),
          //       navigationUrl: '/rec-trading/live-rec-projects',
          //     },
          //     {
          //       title: t('header.projectPreviousRec'),
          //       navigationUrl: '/rec-trading/project-prev-purchased',
          //     },
          //     {
          //       title: t('header.completedOrders'),
          //       navigationUrl: '/rec-trading/order-completed',
          //     },
          //     {
          //       title: t('header.transactionHistory'),
          //       navigationUrl: '/rec-trading/transaction-history',
          //     },
          //   ],
          // },
        ]
      : [
          {
            title: t('header.myDashboard'),
            navigationUrl: '/',
            children: [],
          },
          {
            title: t('header.fuelTrading'),
            navigationUrl: '/buyer/fuel-trading',
            children: [
              {
                title: t('header.myDashboard'),
                navigationUrl: '/buyer/fuel-trading',
              },
              {
                title: t('header.myWatchlist'),
                navigationUrl: '/my-watchlist',
              },
              {
                title: t('header.liveAuctions'),
                navigationUrl: '/live-auctions',
              },
              {
                title: t('header.myAuctions'),
                navigationUrl: '/buyer/my-auctions',
              },
              {
                title: t('header.ordersInProgress'),
                navigationUrl: '/fuel-trading/orders-in-progress',
              },
              {
                title: t('header.completedOrders'),
                navigationUrl: '/fuel-trading/order-completed',
              },
              {
                title: t('header.transactionHistory'),
                navigationUrl: '/fuel-trading/transaction-history',
              },
              {
                title: t('header.pastAuctions'),
                navigationUrl: '/fuel-trading/past-auctions',
              },
            ],
          },
          // {
          //   title: t('header.greenTracing'),
          //   navigationUrl: '/green-tracing',
          //   children: [
          //     {
          //       title: t('header.myDashboard'),
          //       navigationUrl: '/green-tracing',
          //     },
          //     {
          //       title: t('header.myWatchlist'),
          //       navigationUrl: '/green-tracing/my-watchlist',
          //     },
          //     // {
          //     //   title: t('header.allProjects'),
          //     //   navigationUrl: '',
          //     // }
          //   ],
          // },
          // {
          //   title: t('header.recTrading'),
          //   navigationUrl: '/rec-trading',
          //   children: [
          //     {
          //       title: t('header.myDashboardRec'),
          //       navigationUrl: '/rec-trading',
          //     },
          //     {
          //       title: t('header.myWatchlistRec'),
          //       navigationUrl: '/rec-trading/my-watchlist',
          //     },
          //     {
          //       title: t('header.liveRecProjectsRec'),
          //       navigationUrl: '/rec-trading/live-rec-projects',
          //     },
          //     {
          //       title: t('header.projectPreviousRec'),
          //       navigationUrl: '/rec-trading/project-prev-purchased',
          //     },
          //     {
          //       title: t('header.completedOrders'),
          //       navigationUrl: '/rec-trading/order-completed',
          //     },
          //     {
          //       title: t('header.transactionHistory'),
          //       navigationUrl: '/rec-trading/transaction-history',
          //     },
          //   ],
          // },
        ]

  const menuBarCurrentlyActive = (hl: IHeaderLink) => {
    return (
      hl.navigationUrl == location.pathname ||
      (hl.children &&
        hl.children.length > 0 &&
        hl.children.map((c) => c.navigationUrl).indexOf(location.pathname) !== -1)
    )
  }

  return (
    <>
      <header className="w-full bg-white">
        <div className="container mx-auto">
          <div className="flex w-full items-center justify-between py-[6px] align-middle 2xl:py-[8px]">
            <a href="/" className="flex w-fit items-center ">
              <div className="aspect-ratio: 1 / 1; md:hidden lg:hidden xl:hidden 2xl:block">
                <img className="h-[60px] object-cover" src="/regen-updated.png" alt=""></img>
              </div>
              <div className="aspect-ratio: 14 /23; md:block lg:block xl:block 2xl:hidden">
                <img className="object-cover" src="/regen-logo-sm.svg" alt=""></img>
              </div>
            </a>
            {/* Menu start */}
            <Menubar className="item-center border-none">
              {headerLinks.map((hl, index) => {
                return menuBarCurrentlyActive(hl) ? (
                  <MenubarMenu key={`${hl.title}-child-` + index}>
                    <MenubarTrigger
                      onClick={(event) => {
                        if (!menuBarCurrentlyActive(hl)) {
                          navigate(hl.navigationUrl)
                          event.preventDefault()
                        }
                      }}
                      className={cn(
                        'xl:text-md cursor-pointer rounded-lg px-5 py-4 text-base hover:rounded-2xl hover:bg-gray2 hover:px-5 hover:py-4 hover:font-bold active:bg-gray2 active:font-bold 2xl:text-xl',
                        menuBarCurrentlyActive(hl) &&
                          'rounded-2xl bg-accent bg-gray2 px-5 py-4 font-bold text-accent-foreground',
                      )}
                    >
                      {hl.title}
                    </MenubarTrigger>
                    {hl.children && hl.children.length > 0 && (
                      <MenubarContent>
                        {hl.children &&
                          hl.children.map((c, index) => {
                            return (
                              <MenubarItem
                                key={'child-' + index}
                                className="cursor-pointer"
                                onClick={() => navigate(c.navigationUrl)}
                              >
                                {c.title}
                              </MenubarItem>
                            )
                          })}
                      </MenubarContent>
                    )}
                  </MenubarMenu>
                ) : (
                  <div
                    key={`${hl.title}-` + index}
                    onClick={() => navigate(hl.navigationUrl)}
                    className={cn(
                      'xl:text-md flex cursor-pointer select-none items-center rounded-lg px-5 py-4 text-base font-medium outline-none hover:rounded-2xl hover:bg-gray2 hover:px-5 hover:py-4 hover:font-bold focus:bg-accent focus:text-accent-foreground active:bg-gray2 active:font-bold data-[state=open]:bg-accent data-[state=open]:text-accent-foreground 2xl:text-xl',
                      menuBarCurrentlyActive(hl) &&
                        'rounded-2xl bg-accent bg-gray2 px-5 py-4 font-bold text-accent-foreground',
                    )}
                  >
                    {hl.title}
                  </div>
                )
              })}
            </Menubar>
            {/* menu end */}
            <div className="flex items-center justify-between align-middle">
              <div className="relative flex h-[50px] items-center align-middle xl:w-[220px] 2xl:h-[60px] 2xl:w-[250px]">
                <input
                  type="search"
                  className="m-0 block w-[1px] min-w-0 flex-auto rounded-[20px]  bg-gray2 bg-clip-padding py-4 pl-12 text-base font-normal text-[#828282] outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:text-gray focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
                  placeholder={t('header.searchPlaceholder')}
                  aria-label="Search"
                  aria-describedby="button-addon2"
                />

                <span className="input-group-text absolute left-5 top-3.5 flex items-center whitespace-nowrap rounded text-center  text-base font-normal text-neutral-700 2xl:top-5">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                    <path
                      fillRule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </div>

              <div className="pl-3">
                <a
                  href="/"
                  className="flex w-fit items-center gap-[10px] rounded-[1.25rem] bg-darkblue p-4 text-white 2xl:p-[18.7px]"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="23" viewBox="0 0 26 23" fill="none">
                    <path
                      d="M13.3333 10.3333V9.75M18 10.3333V9.75M8.66663 10.3333V9.75M3.37463 17.1327C1.66663 15.7665 1.66663 14.7328 1.66663 10.3333C1.66663 5.93383 1.66663 3.7335 3.37463 2.36733C5.08496 1 7.83363 1 13.3333 1C18.833 1 21.5828 1 23.2908 2.36733C25 3.7335 25 5.93383 25 10.3333C25 14.7328 25 15.7665 23.2908 17.1327C21.584 18.5 18.833 18.5 13.3333 18.5C10.405 18.5 8.89996 20.5277 6.33329 22V18.2527C5.05696 18.0625 4.11779 17.7277 3.37463 17.1327Z"
                      stroke="white"
                      strokeWidth="1.75"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {/* <img src="/chat.svg" className="h-6 w-6 object-cover"></img>
                <p>Chat with us</p> */}
                </a>
              </div>
              <Menubar className="h-auto space-x-1 rounded-none border-none p-0">
                <MenubarMenu>
                  <MenubarTrigger>
                    <div className="w-fit">
                      <div className="relative flex items-center justify-center gap-4">
                        <Avatar className="aspect-circle flex h-12 w-12 justify-center rounded-full border object-cover">
                          <AvatarImage className="object-contain" src={user?.profilePicUrl ?? ''} />
                          <AvatarFallback>
                            {user?.firstName?.substring(0, 1).toUpperCase()}{' '}
                            {user?.lastName?.substring(0, 1).toUpperCase()}
                          </AvatarFallback>
                        </Avatar>
                        <div className="flex w-[150px] cursor-pointer items-center justify-between align-middle capitalize 2xl:w-[180px]">
                          <div className="flex w-full flex-col text-left">
                            <div className="2lx:leading-[17px] max-h-12 overflow-hidden truncate text-base font-bold capitalize leading-[19px] text-secoundary 2xl:text-xl ">
                              {user?.firstName?.toLowerCase()} {user?.lastName?.toLowerCase()}
                            </div>
                            <div className="w-full overflow-hidden truncate text-base font-normal   capitalize text-secoundary">
                              {user?.companyName}
                            </div>
                          </div>
                          <div className="ml-5 flex items-center align-middle">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              fill="none"
                            >
                              <path
                                d="M1.645 0.509766L7 5.86477L12.355 0.509766L14 2.16643L7 9.16643L0 2.16643L1.645 0.509766Z"
                                fill="#333333"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </MenubarTrigger>
                  <MenubarContent>
                    <MenubarItem className="cursor-pointer" onClick={() => navigate('/profile')}>
                      Profile
                    </MenubarItem>
                    <MenubarItem>Terms of Usage</MenubarItem>
                    <MenubarItem>Privacy policy</MenubarItem>
                    <MenubarItem>FAQ</MenubarItem>
                    <MenubarItem onClick={logOut}>Log Out</MenubarItem>
                  </MenubarContent>
                </MenubarMenu>
              </Menubar>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
