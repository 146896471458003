import { type Chat, useMessages } from '@minchat/react'
import { MinChatUI } from '@minchat/reactui'
import { useUserStore } from 'src/_store/user.store'
import { useEffect } from 'react'

const ChatContainer = ({ chat }: { chat: Chat }) => {
  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  if (!user) return null

  const currentUser = {
    username: `user-${user.id}`,
    name: `${user.firstName} ${user.lastName}`,
  }

  const { messages, loading, error, paginate, paginateLoading, sendMessage, updateMessage, deleteMessage } =
    useMessages(chat)

  useEffect(() => {
    if (messages && messages.length === 0) {
      sendMessage({
        text: 'Test Message',
      })
    }
  }, [messages])

  return (
    <MinChatUI
      user={currentUser}
      apiKey="CLVVYLZFM03UG3MHB8M3F91BR"
      theme="#6ea9d7"
      height="100%"
      renderChatList={({ connectedUser, chats, loading, selectedChat, paginate, openChat, isMobile }) => {
        openChat(chat)
        return <></>
      }}
    />
  )
}

export default ChatContainer
