import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'src/components/ui/pagination'
import { useState } from 'react'
import FuelTradingTopTicker2 from 'src/components/fuel-trading/top-ticker2'

import CreateAuction from 'src/pages/supplier/fuel-trading/create-auction'
import { useSearchParams } from 'react-router-dom'
import AuctionFilterBtn from 'src/components/auction/auction-filter-btn'
import * as React from 'react'

export default function AuctionsSupplier() {
  const [page, setPage] = useState(1)
  const [searchParams, _] = useSearchParams()

  const params = searchParams.toString()
  const myAuctionQuery = useQuery({
    queryKey: ['supplier-my-auctions', `page-${page}`, 'auctions', params],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get(`/api/supplier/auctions?${params}&page=${page}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[]; totalPages: number }>>
    },
    cacheTime: 60,
  })

  return (
    <>
      <FuelTradingTopTicker2 />
      <section className="mb-5 w-full">
        <div className="container mx-auto bg-transparent ">
          <div className="mb-[30px] flex w-full justify-between">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">My Auctions</h2>
            <AuctionFilterBtn />
          </div>
          {/* <Carousel /> */}
          <div>
            <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-5 2xl:grid-cols-5">
              <CreateAuction className="mb-5 mt-0" />
              {myAuctionQuery.data &&
                myAuctionQuery.data.data.auctions.length > 0 &&
                myAuctionQuery.data.data.auctions.map((auction) => {
                  return (
                    <div className="mb-[15px]" key={`my-auctions-${auction.id}`}>
                      <AuctionCard key={auction.id} auction={auction} />
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </section>
      {myAuctionQuery.data && myAuctionQuery.data.data.totalPages > 1 && (
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious onClick={() => setPage((page) => page - 1)} />
            </PaginationItem>
            {Array.from(Array(myAuctionQuery.data.data.totalPages), (e, i) => {
              return (
                <PaginationItem key={i}>
                  <PaginationLink onClick={() => setPage(i)}>i</PaginationLink>
                </PaginationItem>
              )
            })}
            {/*<PaginationItem>*/}
            {/*  <PaginationEllipsis />*/}
            {/*</PaginationItem>*/}
            <PaginationItem>
              <PaginationNext onClick={() => setPage((page) => page + 1)} />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )}
      {/* mywatch list end */}
    </>
  )
}
