import * as React from 'react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'src/components/ui/tabs'
import { Button } from 'src/components/ui/button'
import { Input } from 'src/components/ui/input'
import { Select, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { SelectContent } from '@radix-ui/react-select'
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group'
import { Label } from 'src/components/ui/label'
import ProfilePersonalInformation from 'src/pages/profile/personal-information'
import ProfileCompanyInformation from 'src/pages/profile/company-information'
import ProfileSavedAddresses from 'src/pages/profile/saved-addresses'
import ProfileSavedDocuments from 'src/pages/profile/saved-documents'
import ProfilePaymentMethods from 'src/pages/profile/payment-methods'
import ProfilePasswordManagement from 'src/pages/profile/password-management'
import { useUserStore } from 'src/_store/user.store'
import { redirect, useLocation } from 'react-router-dom'
import ProfileInsuranceProviders from 'src/pages/profile/insurance-providers'

const ProfilePage = () => {
  const { user } = useUserStore((state) => ({ user: state.user }))
  const location = useLocation()
  const defaultTab = location.state?.open_tab || 'personal-information'
  const [selectedTab, setSelectedTab] = React.useState<string>(defaultTab)

  if (!user) {
    redirect('/')
    return
  }

  return (
    <>
      <section className="mx-[81px] w-full xl:my-[60px] 2xl:mx-[200px]">
        <div className="w-full max-w-[1277px] 2xl:max-w-[1519px]">
          <Tabs defaultValue={defaultTab} className="flex h-full w-full">
            <div className="h-screen w-full max-w-[280px] border border-b-0 border-l-0 border-t-0 border-r-[#BDBDBD] pr-[40px] 2xl:max-w-[342px]">
              <div className="flex w-full flex-col">
                <div className="2xl:px-71px xl:px-51px mx-auto mb-10 flex w-full flex-col">
                  <div className="relative mx-auto aspect-square text-center xl:w-[140px] 2xl:w-[160px]">
                    {user && user.profilePicUrl ? (
                      <img src={user.profilePicUrl} className="block h-auto object-cover" />
                    ) : (
                      <div className="flex items-center justify-center bg-gray-200 font-bold text-xl rounded-full text-center w-full h-full">
                        {user?.firstName?.substring(0, 1).toUpperCase()} {user?.lastName?.substring(0, 1).toUpperCase()}
                      </div>
                    )}
                  </div>
                  {/* <a href="#" onClick={()=> setSelectedTab('personal-information')} className="mt-5 text-center text-sm text-primary">
                    Edit Profile Picture
                  </a> */}
                </div>
                <TabsList className="relative top-[180px] flex w-full flex-col items-start gap-[10px] text-left">
                  <TabsTrigger
                    value="personal-information"
                    className="w-full p-0 text-left focus:font-bold active:font-bold 2xl:p-[0]"
                  >
                    <Button
                      variant="tabbtn"
                      size="tabbtn"
                      className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                    >
                      Personal Information
                    </Button>
                  </TabsTrigger>
                  <TabsTrigger
                    value="company-information"
                    className="w-full p-0 focus:font-bold active:font-bold 2xl:p-[0]"
                  >
                    <Button
                      variant="tabbtn"
                      size="tabbtn"
                      className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                    >
                      Company Information
                    </Button>
                  </TabsTrigger>
                  <TabsTrigger value="saved-address" className="w-full p-0 focus:font-bold active:font-bold 2xl:p-[0]">
                    <Button
                      variant="tabbtn"
                      size="tabbtn"
                      className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                    >
                      Saved Addresses
                    </Button>
                  </TabsTrigger>
                  {user.userType === 'SUPPLIER' && (
                    <TabsTrigger
                      value="insurance-providers"
                      className="w-full p-0 focus:font-bold active:font-bold 2xl:p-[0]"
                    >
                      <Button
                        variant="tabbtn"
                        size="tabbtn"
                        className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                      >
                        Insurance Providers
                      </Button>
                    </TabsTrigger>
                  )}
                  <TabsTrigger
                    value="saved-documents"
                    className="w-full p-0 focus:font-bold active:font-bold 2xl:p-[0]"
                  >
                    <Button
                      variant="tabbtn"
                      size="tabbtn"
                      className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                    >
                      Saved Documents
                    </Button>
                  </TabsTrigger>
                  <TabsTrigger
                    value="payment-methods"
                    className="w-full p-0 focus:font-bold active:font-bold 2xl:p-[0]"
                  >
                    <Button
                      variant="tabbtn"
                      size="tabbtn"
                      className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                    >
                      Payment Methods
                    </Button>
                  </TabsTrigger>
                  <TabsTrigger
                    value="password-management"
                    className="w-full p-0 focus:font-bold active:font-bold 2xl:p-[0]"
                  >
                    <Button
                      variant="tabbtn"
                      size="tabbtn"
                      className="line-height w-full justify-start p-5 text-sm 2xl:p-5 2xl:text-base"
                    >
                      Password Management
                    </Button>
                  </TabsTrigger>
                </TabsList>
              </div>
            </div>
            {/* <div className="w-[calc(100%-342px)] pl-20"> */}
            {/* right panel tabls content */}
            {/* personal-information */}
            <div className="w-full max-w-[1097px]">
              <TabsContent value="personal-information">
                <ProfilePersonalInformation user={user} />
              </TabsContent>
              {/* company-information */}
              <TabsContent value="company-information">
                <ProfileCompanyInformation user={user} />
              </TabsContent>
              {/* Saved Address */}
              <TabsContent value="saved-address">
                <ProfileSavedAddresses user={user}/>
              </TabsContent>

              {/* insurance providers */}
              {user.userType === 'SUPPLIER' && (
                <TabsContent value="insurance-providers">
                  <ProfileInsuranceProviders />
                </TabsContent>
              )}

              {/*Saved Documents */}
              <TabsContent value="saved-documents">
                <ProfileSavedDocuments />
              </TabsContent>

              {/* payment Methods */}
              <TabsContent value="payment-methods">
                <ProfilePaymentMethods />
              </TabsContent>

              {/* password management */}
              <TabsContent value="password-management">
                <ProfilePasswordManagement />
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </section>
    </>
  )
}

export default ProfilePage
