import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { useTranslation } from 'react-i18next'

const PaymentCardForm = () => {
  const { t } = useTranslation('translation')
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const cardsCreateMutation = useMutation({
    mutationKey: ['cardsCreate'],
    mutationFn: ({ paymentId }: { paymentId: string }): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        '/api/payments/cards',
        {
          paymentId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // Block native form submission.
    event.preventDefault()
    setIsLoading(true)

    if (!stripe || !elements) {
      setIsLoading(false)
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const card = elements.getElement(CardElement)

    if (card == null) {
      setIsLoading(false)
      return
    }

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card,
    })

    if (error) {
      setIsLoading(false)
      toast({
        variant: 'destructive',
        title: 'Uh oh! Something went wrong.',
        description: error.message,
      })
    } else {
      cardsCreateMutation.mutate(
        {
          paymentId: paymentMethod?.id,
        },
        {
          onSuccess: (resp) => {
            setIsLoading(false)
            toast({
              title: t(resp.data.message),
            })
            card.clear()
            queryClient.invalidateQueries(['paymentCardsQuery'])
          },
          onError: () => {
            setIsLoading(false)
            toast({
              title: 'Card addition failed please retry.',
            })
            card.clear()
          },
        },
      )
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardElement
        options={{
          disableLink: true,
          classes: {
            base: 'text-gray-900 sm:text-sm sm:leading-6 block w-full rounded-md border-[#CCCCCC] p-4 shadow-sm ring-1 placeholder:text-[#CCCCCC]',
            focus: 'ring-1 ring-primary ring-mild',
            invalid: 'ring-[#F33000] focus:ring-[#F33000] active:ring-[#F33000]',
          },
          // style: {
          //   base: {
          //     fontSize: '16px',
          //     color: '#424770',
          //     '::placeholder': {
          //       color: '#aab7c4',
          //     },
          //   },
          //   invalid: {
          //     color: '#9e2146',
          //   },
          // },
        }}
      />
      <Button className="mt-5" type="submit" disabled={!stripe || isLoading}>
        Add Payment
      </Button>
    </form>
  )
}

export default PaymentCardForm
