import { Button } from 'src/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import { IOrder } from 'src/_models/order.model'
import OrderDocumentStatus from 'src/components/order/document-status'
import OrderSignatureGuaranteeView from 'src/components/order/signature-guarantee-view'
import TotalPayableAmount from 'src/components/order/total-payable-amount'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { toast } from 'src/components/ui/use-toast'
import BuyerOrderPaymentMethods from 'src/components/order/buyer-order-payment-methods'

const BuyerOrderBuyerPayment = ({ goNext, order }: { goNext: () => void; order: IOrder }) => {
  const queryClient = useQueryClient()
  const partialPayoutToCustomerMutation = useMutation({
    mutationFn: ({ orderId }: { orderId: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/buyer/order/payout/${orderId}/partial-buyer-payout`,
        {},
        {
          // responseType: 'blob',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const partialPayoutToCustomer = () => {
    partialPayoutToCustomerMutation.mutate(
      { orderId: order.id },
      {
        onSuccess: () => {
          toast({
            title: 'Payment done.',
          })
          queryClient.invalidateQueries()
          goNext()
        },
        onError: () => {
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: 'Payment failed.',
          })
        },
      },
    )
  }

  return (
    <>
      <div className="flex max-h-[calc(90vh-538px)] flex-col overflow-y-scroll px-[30px] ">
        <OrderDocumentStatus order={order} />
        <OrderSignatureGuaranteeView
          order={order}
          type="BUYER"
          title="Buyer Order Guarantee Signature"
          description="Please sign or upload signature to confirm your purchase. After signing you will not be able to rescind the order."
          orderGuaranteeText={order.buyerOrderGuaranteeText}
          orderGuaranteeMediaKey={order.buyerOrderGuaranteeMedia?.fileKey}
        />
        <OrderSignatureGuaranteeView
          order={order}
          type="SUPPLIER"
          title="Supplier Order Guarantee Signature"
          description="Once the Supplier signs, the order will be final and must go through."
          orderGuaranteeText={order.supplierOrderGuaranteeText}
          orderGuaranteeMediaKey={order.supplierOrderGuaranteeMedia?.fileKey}
        />
      </div>
      <div className="mt-5 h-40 px-[30px]">
        <hr className=" mt-[10px] h-px border-0 bg-[#E0E0E0]" />
        <TotalPayableAmount order={order} />
        <div className="flex gap-5">
          <DialogClose asChild>
            <Button variant="secondary">Cancel</Button>
          </DialogClose>
          <Button disabled={partialPayoutToCustomerMutation.isLoading} onClick={partialPayoutToCustomer}>
            Deposit Now
          </Button>
        </div>
      </div>
    </>
  )
}

export default BuyerOrderBuyerPayment
