import CurrencyFormatter from "src/components/ui/currency-formatter";
import * as React from "react";
import {IAuction} from "src/_models/auction.model";
import {useTranslation} from "react-i18next";

const AuctionDialogEstimatedShippingAndShippingRadius = ({auction }: {auction: IAuction}) => {
  const {t} = useTranslation('translation')
  return (
    <>
    <div className="mb-[10px] flex w-full items-center justify-between">
      <div className=" block text-sm text-secoundary 2xl:text-base">Estimated Shipping Cost</div>
      <div className=" block text-sm text-secoundary 2xl:text-base">
        <CurrencyFormatter
          currency={auction.shippingDetail.shippingCurrency}
          amount={auction.shippingDetail.costPerUnitDistance}
          cents={true}
        /> / {t(`shippingRadiusUnit.${auction.shippingDetail.shippingRadiusUnit}`)}
      </div>
    </div>
  <div className="mb-[10px] flex w-full items-center justify-between">
    <div className=" block text-sm text-secoundary 2xl:text-base">Shipping Radius</div>
    <div className=" block text-sm text-secoundary 2xl:text-base">
      {auction.shippingDetail.shippingRadius} {t(`shippingRadiusUnit.${auction.shippingDetail.shippingRadiusUnit}`)}
    </div>
  </div>
  </>
);
}

export default AuctionDialogEstimatedShippingAndShippingRadius;
