import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog'
import { Button } from 'src/components/ui/button'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from 'src/_store/user.store'
import { useQueryClient } from '@tanstack/react-query'

const BackLogoutBtn = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { setUser } = useUserStore((store) => ({
    setUser: store.setUser,
  }))

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="secondary">Back</Button>
      </DialogTrigger>
      <DialogContent className="rounded-[20px]">
        <DialogHeader>
          <DialogTitle>Are you absolutely sure?</DialogTitle>
          <DialogDescription>This action cannot be undone. Are you sure you want to logout ?</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <div className="flex gap-2">
            <div className="w-full"></div>
            <Button
              type="button"
              onClick={() => {
                setUser(null)
                localStorage.removeItem('token')
                queryClient.invalidateQueries()
                navigate('/auth/login')
              }}
            >
              Logout
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default BackLogoutBtn
