import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import FuelTradingTopTicker2 from 'src/components/fuel-trading/top-ticker2'

import FuelTradingBuyerMyAuctions from 'src/pages/buyer/fuel-trading/my-auctions'
import MyWatchlist from 'src/components/fuel-trading/my-watchlist'
import LiveAuctions from 'src/components/fuel-trading/live-auctions'
import StickyRightComponent from 'src/components/common/sticky-right'
import DashboardOrdersInProgress from 'src/pages/fuel-trading/dashboard-orders-in-progress'
import DashboardOrdersCompleted from 'src/pages/fuel-trading/dashboard-orders-completed'
import * as React from 'react'
import StatesFilter from 'src/components/states-filter'

export default function BuyerFuelTradingDashboard() {
  return (
    <>
      <FuelTradingTopTicker2/>
      <section className="mb-10 w-full">
        <div className="container mx-auto flex flex-row-reverse">
          <StatesFilter filterType="FUEL_TRADING" className="w-52"/>
        </div>
      </section>
      <MyWatchlist/>
      {/* Historial fuel start */}
      <section className="mt-10 w-full">
        <div className="container mx-auto">
          <div className="mb-[15px] flex w-full">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Historical Fuel Pricing</h2>
          </div>
          <div className="flex w-full">
            <img src="/history-img.png" alt="historical data"/>
          </div>
        </div>
      </section>
      {/* historial fuel end */}
      <LiveAuctions/>
      <FuelTradingBuyerMyAuctions/>
      <section className="mb-10 mt-10 w-full">
        <div className="container mx-auto flex gap-8">
          <DashboardOrdersInProgress/>
          <DashboardOrdersCompleted/>
        </div>
      </section>
      <StickyRightComponent type="FUEL_TRADING"/>
    </>
  )
}
