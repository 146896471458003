import React from 'react'
import Marquee from 'react-fast-marquee'

interface Fuel {
  name: string
  purity: number
  price: string
  color: string
}

interface Props {
  fuelData: Fuel[]
}

const FuelTradingTopTicker2 = () => {
  //TODO: change later to api here itself
  const fuelData = [
    { name: 'Green Hydrogen', purity: 99.9999, price: '$5.00/kg', color: 'text-green' },
    { name: 'Green Hydrogen', purity: 99.999, price: '$5.00/kg', color: 'text-red' },
    { name: 'Blue Hydrogen', purity: 99.999, price: '3.00/kg', color: 'text-green' },
    { name: 'Blue Hydrogen', purity: 99.95, price: '$2.65/kg', color: 'text-green' },
    { name: 'Blue Hydrogen', purity: 99.5, price: '$2.20/kg', color: 'text-red' },
    { name: 'Turquoise Hydrogen', purity: 99.999, price: '$4.35/kg', color: 'text-red' },
    { name: 'Turquoise Hydrogen', purity: 99.9999, price: '$4.35/kg', color: 'text-green' },
    { name: 'Grey Hydrogen', purity: 99.9999, price: '$2.00/kg', color: 'text-red' },
    { name: 'Grey Hydrogen', purity: 99.999, price: '$2.60/kg', color: 'text-green' },
    { name: 'Grey Hydrogen', purity: 99.99, price: '$2.35/kg', color: 'text-red' },
    { name: 'Grey Hydrogen', purity: 99.95, price: '$2.15/kg', color: 'text-green' },
    { name: 'Grey Hydrogen', purity: 99.5, price: '$1.80/kg', color: 'text-green' },
    { name: 'Grey Hydrogen', purity: 99.0, price: '$1.50/kg', color: 'text-green' },
    { name: 'Green Ammonia', purity: 99.995, price: '$1.40/kg', color: 'text-red' },
    { name: 'Green Ammonia', purity: 99.99, price: '$1.10/kg', color: 'text-red' },
    { name: 'Green Ammonia', purity: 99.98, price: '$0.90/kg', color: 'text-red' },
    { name: 'Green Ammonia', purity: 99.5, price: '$0.70/kg', color: 'text-red' },
    { name: 'Blue Ammonia', purity: 99.99, price: '$0.60/kg', color: 'text-green' },
    { name: 'Blue Ammonia', purity: 99.98, price: '$0.52/kg', color: 'text-green' },
    { name: 'Blue Ammonia', purity: 99.5, price: '$0.49/kg', color: 'text-red' },
    { name: 'Grey Ammonia', purity: 99.99, price: '$0.45/kg', color: 'text-green' },
    { name: 'Grey Ammonia', purity: 99.98, price: '$0.40/kg', color: 'text-red' },
    { name: 'Grey Ammonia', purity: 99.5, price: '$0.38/kg', color: 'text-green' },
    { name: 'Green Methanol', purity: 99.9, price: '$1.60/kg', color: 'text-green' },
    { name: 'Green Methanol', purity: 99.0, price: '$1.35/kg', color: 'text-green' },
    { name: 'Blue Methanol', purity: 99.9, price: '0.48/kg', color: 'text-green' },
    { name: 'Blue Methanol', purity: 99.0, price: '$0.40/kg', color: 'text-green' },
    { name: 'Blue Methanol', purity: 99.8, price: '$0.38/kg', color: 'text-green' },
    { name: 'Grey Methanol', purity: 99.8, price: '$0.35/kg', color: 'text-green' },
    { name: 'Grey Methanol', purity: 99.7, price: '$0.30/kg', color: 'text-green' },
  ]

  return (
    <div className="w-full">
      <div className="border-1 container mx-auto mb-5 flex pt-[19px] align-middle">
        <div className="shadow-[0px 4px 8px 0px rgba(2, 48, 71, 0.08), 0px 0px 4px 0px rgba(2, 48, 71, 0.06)] w-full items-center overflow-hidden overflow-x-hidden rounded-lg bg-white p-5 align-middle">
          <div className="overflow-x-hidden">
            <Marquee>
              <div className="flex whitespace-nowrap">
                {fuelData.map((fuel, index) => (
                  <React.Fragment key={index}>
                    <div className="flex items-center align-middle">
                      <p className="mr-[5px] font-bold text-secoundary xl:text-sm 2xl:text-base">{fuel.name}</p>
                      <div className="mr-9 w-fit rounded-sm bg-gray2">
                        <p className="px-2 py-1 text-center text-xs font-bold text-primary">{fuel.purity} %</p>
                      </div>
                      <p className={`text-xs font-bold ${fuel.color} 2xl:text-sm`}>{fuel.price}</p>
                    </div>
                    <div className='before:ml-5 before:mr-5 before:content-["|"]'></div>
                  </React.Fragment>
                ))}
              </div>
            </Marquee>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FuelTradingTopTicker2
