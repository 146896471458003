import { MinChatUI } from '@minchat/reactui'
import { Chat, useMinChat } from '@minchat/react'
import { useUserStore } from 'src/_store/user.store'
import { useEffect, useState } from 'react'
import { IOrder } from 'src/_models/order.model'
import ChatContainer from 'src/components/chat/chat-container'

const ChatAuctionDialog = ({ order }: { order: IOrder }) => {
  const minchat = useMinChat()
  const [chat, setChat] = useState<Chat | null>(null)

  useEffect(() => {
    minGenerateGroupChat()
  }, [])

  const minGenerateGroupChat = async () => {
    if (!minchat || !user) return

    // await minchat.createUser({
    //   username: `user-${order.buyer.id}`,
    //   name: `${order.buyer.firstName} ${order.buyer.lastName}`,
    // })
    //
    // await minchat.createUser({
    //   username: `user-${order.supplier.id}`,
    //   name: `${order.supplier.firstName} ${order.supplier.lastName}`,
    // })
    //
    // await minchat.createUser({
    //   username: 'regen-admin',
    //   name: 'Regen Admin',
    // })
    //
    // await minchat.createUser({
    //   username: order.auction.generatedId,
    //   name: order.auction.generatedId,
    // })

    // if (user.userType === 'BUYER') {
    //   console.log('creating buyer chat', order.supplier.id, user.id)
    //   await minchat
    //     .chat(`user-${order.supplier.id}`)
    //     .then((chat) => setChat(chat))
    //     .catch((e) => {
    //       console.log('minchat error', e)
    //     })
    // } else {
    //   console.log('creating supplier chat', order.buyer.id, user.id)
    //   await minchat
    //     .chat(`user-${order.buyer.id}`)
    //     .then((chat) => setChat(chat))
    //     .catch((e) => {
    //       console.log('minchat error', e)
    //     })
    // }

    alert('user.userType: ' + user.userType)
    if (user.userType === 'SUPPLIER') {
      const chat = await minchat
        .groupChat({
          title: order.auction.generatedId,
          memberUsernames: [
            `user-${order.buyer.id}`,
            order.auction.generatedId,
            `user-${order.supplier.id}`,
            'regen-admin',
          ],
        })
        .then((chat) => {
          console.log('chat', chat)
          setChat(chat)
        })
        .catch((e) => {
          console.log('minchat error', e)
        })
    } else {
      await minchat
        .groupChat({
          title: order.auction.generatedId,
          memberUsernames: [
            `user-${order.buyer.id}`,
            order.auction.generatedId,
            `user-${order.supplier.id}`,
            'regen-admin',
          ],
        })
        .then((chat) => {
          console.log('chat', chat)
          setChat(chat)
        })
        .catch((e) => {
          console.log('minchat error', e)
        })
    }
  }

  const { user } = useUserStore((state) => ({
    user: state.user,
  }))

  if (!user || !minchat) return null

  return <div className="">{chat && <ChatContainer chat={chat} />}</div>
}

export default ChatAuctionDialog
