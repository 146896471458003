import { IAuction, IAuctionBuyer, IAuctionSupplier, IBidBuyer, IBidSupplier } from 'src/_models/auction.model'
import * as React from 'react'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button } from 'src/components/ui/button'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import AuctionDialogTopHeader from 'src/components/auction/auction-dialog-top-header'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { differenceInDays } from 'date-fns'
import { DialogClose } from '../ui/dialog'
import { Input } from '../ui/input'
import ErrorMessage from '../ui/error-message'
import { Label } from '../ui/label'
import { Checkbox } from '../ui/checkbox'
import { useUserStore } from 'src/_store/user.store'
import { FUEL_FEES_CALC, BUYER_FEES_CALC, TAXES_CALC } from 'src/lib/constants'
import CurrencyFormatter from 'src/components/ui/currency-formatter'
import AuctionDialogHeader from 'src/components/auction/auction-dialog-header'
import AuctionDialogEstimatedShippingAndShippingRadius from 'src/components/auction/auction-dialog-estimated-shipping-and-shipping-radius'
import { isPastAuction } from 'src/lib/auction.util'
type IBidForm = {
  bidAmount: number
}

const BuyerAuctionUpdateBidDialog = ({
  auction,
  setShowDialog,
}: {
  auction: IAuction
  setShowDialog: Dispatch<SetStateAction<boolean>>
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('translation')
  const { user } = useUserStore((state) => {
    return {
      user: state.user,
    }
  })

  const buyerAuctionQuery = useQuery({
    queryKey: [`buyerAuction-${auction.id}-${new Date()}`],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{ auction: IAuctionBuyer }>(`/api/buyer/auctions/${auction.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const { control, watch } = useForm<IBidForm>({
    defaultValues: {
      bidAmount: auction.auctionType === 'AUCTION' ? 0 : (auction.sellingPricePerUnit / 100.0) * auction.fuelWeight,
    },
  })
  const [confirm, setConfirm] = useState<boolean>(false)
  const [placeConfirm, setPlaceConfirm] = useState<boolean>(false)
  const [anonymous, setAnonymous] = useState<boolean>(false)
  const [stateTaxes, setStateTaxes] = useState(0)
  const [cityTaxes, setCityTaxes] = useState(0)
  // const [shippingCost, setShippingCost] = useState(0)
  const [fuelFees, setFuelFees] = useState(0)
  const [buyerFees, setBuyerFees] = useState(0)
  const [highestBid, setHighestBid] = useState<IBidBuyer | null>()
  const [myHighestBid, setMyHighestBid] = useState<IBidBuyer | null>()

  useEffect(() => {
    if (buyerAuctionQuery.data) {
      setHighestBid(buyerAuctionQuery.data.data.auction.highestBid)
      setMyHighestBid(buyerAuctionQuery.data.data.auction.myHighestBid)
    }
  }, [buyerAuctionQuery.data])

  const getHighestBid = () => {
    let highest = auction.sellingPricePerUnit * auction.fuelWeight

    if (highestBid) {
      highest = highestBid.bidAmount
    }

    return highest / 100
  }

  const yourCurrentBid = () => {
    let currentBid: IBidSupplier | undefined
    for (const bid of auction.bids) {
      if (user?.id === bid.userId) currentBid = bid
    }
    return currentBid
  }

  const fuelWeightUnitText = t('fuelWeightUnit.' + auction.fuelWeightUnit)

  const getUnitPrice = () => {
    const price = auction.sellingPricePerUnit / 100.0
    return price
  }
  const getUnitPriceCurrent = () => {
    let unitPriceCurrent
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) unitPriceCurrent = auction.sellingPricePerUnit / 100.0
    }
    return unitPriceCurrent
  }

  const bidAmount = watch('bidAmount')

  const getBidPrice = () => {
    return (bidAmount || 0) / auction.fuelWeight
  }
  const getBidPriceCurrent = () => {
    return (getHighestBid() || 0) / auction.fuelWeight
  }

  const getCurrentBid = () => {
    let currentBid = 0
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) currentBid = bid.bidAmount
    }
    return currentBid / 100
  }

  const getStateTaxAmount = () => {
    if (placeConfirm) {
      return stateTaxes
    }
    let stateTaxAmount = 0
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) stateTaxAmount = bid.stateTaxAmount / 100
    }
    return stateTaxAmount
  }

  const getCityTaxAmount = () => {
    if (placeConfirm) {
      return cityTaxes
    }
    let cityTaxAmount = 0
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) cityTaxAmount = bid.stateTaxAmount / 100
    }
    return cityTaxAmount
  }

  const getFuelFeesAmount = () => {
    let fuelFeesAmount = 0
    if (placeConfirm) {
      return fuelFees
    }
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) fuelFeesAmount = bid.fuelFeesAmount / 100
    }
    return fuelFeesAmount
  }

  const getBuyerFeesAmount = () => {
    let buyerFeesAmount = 0
    if (placeConfirm) {
      return buyerFees
    }
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) buyerFeesAmount = bid.buyerFeesAmount / 100
    }
    return buyerFeesAmount
  }

  const getSupplierFeesAmount = () => {
    let supplierFeesAmount = 0
    if (placeConfirm) {
      return supplierFeesAmount
    }
    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) supplierFeesAmount = bid.supplierFeesAmount / 100
    }
    return supplierFeesAmount
  }

  const getUpdatedBid = () => {
    return bidAmount
  }
  const getUpdatedTotal = () => {
    return getStateTaxAmount() + getCityTaxAmount() + getFuelFeesAmount() + getBuyerFeesAmount() + bidAmount // + getShippingCost()
  }

  useEffect(() => {
    setStateTaxes(bidAmount ? bidAmount * (+auction.stateTaxPercentage || 10) : 0)
    setCityTaxes(bidAmount ? bidAmount * +(auction.cityTaxPercentage || 0) : 0)
    // setShippingCost(SHIPPING_COST)
    setFuelFees(bidAmount ? bidAmount * FUEL_FEES_CALC : 0)
    setBuyerFees(bidAmount ? bidAmount * BUYER_FEES_CALC : 0)
  }, [bidAmount])

  const total = () => {
    // let highest = 0
    let totalAmount

    for (const bid of auction.bids) {
      if (user && user.id === bid.userId) totalAmount = bid.totalAmount / 100
    }
    // const sum = taxes + shippingCost + fees + bidAmount
    return totalAmount
  }

  const bidMutation = useMutation({
    mutationFn: ({ bidAmount }: { bidAmount: number }) => {
      const token = localStorage.getItem('token')
      return axios.post(
        `/api/buyer/auctions/${auction.id}`,
        {
          bidAmount,
          anonymous,
          taxAmount: getStateTaxAmount() + getCityTaxAmount(),
          fuelFees: fuelFees,
          buyerFees: buyerFees,
          // shippingCost: shippingCost,
          totalAmount: total(),
          bidConfirmTime: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const performBidMutation = () => {
    bidMutation.mutate(
      {
        bidAmount,
      },
      {
        onSuccess: () => {
          toast({
            title: 'Bid completed.',
          })
          setShowDialog(false)
          queryClient.invalidateQueries()
        },
        onError: (e) => {
          const error = e as AxiosError<{ message: string }>
          toast({
            variant: 'destructive',
            title: 'Uh oh! Something went wrong.',
            description: error.response?.data?.message,
          })
        },
      },
    )
  }
  const days = differenceInDays(auction.auctionEndDate, new Date())

  // If no bid from user or highest bid != user has the highest bid then allowed to update the bid
  const allowedUpdateBid = !myHighestBid || highestBid?.id !== myHighestBid?.id

  return (
    <div
      className="mx-auto h-full w-full max-w-4xl rounded-[40px] bg-white
    py-[30px] pl-[30px] pr-3 font-montserrat"
    >
      <div className="text-secoundary">
        <AuctionDialogHeader auction={auction} />
      </div>
      <hr className="mb-4 mr-7 mt-5 h-px border-0 bg-[#E0E0E0] 2xl:mb-[10px]" />
      <div className="max-h-[calc(100vh_-_440px)] overflow-auto pr-7">
        <AuctionDialogTopHeader auction={auction} />
        <div className="my-[10px] flex w-full flex-col rounded-[10px] border-[1px] border-[#E0E0E0] bg-[#F2F2F2] py-[10px] 2xl:my-[15px]">
          <div className="flex justify-around divide-x-[1px] divide-verticalline align-middle">
            <div className="flex w-full flex-col items-center justify-center gap-[10px]">
              <p className=" block text-sm font-normal text-secoundary 2xl:text-base">Quantity</p>
              <p className="block text-base font-bold text-secoundary 2xl:text-xl">
                {/* {t('fuelWeightUnit.' + auction.fuelWeightUnit)} */}
                {auction.fuelWeight} {fuelWeightUnitText}
              </p>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-[10px]">
              <p className="block text-sm font-normal text-secoundary 2xl:text-base">
                {auction.bids.length > 0 ? 'Highest Bid' : 'Floor Price'}
              </p>
              <p className="block text-base font-bold text-secoundary 2xl:text-xl">
                <CurrencyFormatter currency={auction.sellingCurrency} amount={getHighestBid()} cents={false} />
              </p>
            </div>
            <div className="flex w-full flex-col items-center justify-center gap-[10px]">
              <p className="flex gap-1 text-sm font-normal text-secoundary 2xl:text-base">Total Bids</p>

              <p className="block text-base font-bold text-secoundary 2xl:text-xl">{auction.bids.length}</p>
            </div>
          </div>
        </div>
        {confirm ? (
          <>
            <div className="mt-[10px] flex w-full flex-col rounded-lg 2xl:mt-[15px]">
              <div className="flex flex-col justify-around">
                <div className="flex w-full items-center justify-between pb-4 align-middle">
                  <p className=" block pl-[30px]  text-sm text-secoundary 2xl:text-base">Current Highest Bid</p>

                  <div className="flex items-center gap-[10px] align-middle">
                    {/* <p className="text-yellow-300">
                 <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                   <path
                     d="M8.78538 9.63417L13.5967 9.72769L10.3065 6.21558L11.8822 1.66472L7.5244 3.70342L3.69538 0.818034L4.27969 5.57676L0.335208 8.33138L5.05843 9.24558L6.46069 13.8477L8.78538 9.63417Z"
                     fill="#F2C94C"
                   />
                 </svg>
               </p> */}
                    <p className="block w-[200px] text-sm font-bold text-secoundary 2xl:text-base">
                      <div className="border:none rounded-[10px] bg-[#F2F2F2] py-4  pr-[30px] text-right ring-0">
                        <CurrencyFormatter currency={auction.sellingCurrency} amount={getCurrentBid()} cents={false} />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="flex w-full items-center justify-between align-middle">
                  <p className=" block pl-[30px]  text-sm text-secoundary 2xl:text-base">Updated Bid</p>

                  <div className="flex items-center gap-[10px] align-middle">
                    {/* <p className="text-yellow-300">
                 <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                   <path
                     d="M8.78538 9.63417L13.5967 9.72769L10.3065 6.21558L11.8822 1.66472L7.5244 3.70342L3.69538 0.818034L4.27969 5.57676L0.335208 8.33138L5.05843 9.24558L6.46069 13.8477L8.78538 9.63417Z"
                     fill="#F2C94C"
                   />
                 </svg>
               </p> */}
                    <p className="block w-[200px] text-sm font-bold text-secoundary 2xl:text-base">
                      <div className="border:none rounded-[10px] bg-[#F2F2F2] py-4  pr-[30px] text-right ring-0">
                        <CurrencyFormatter currency={auction.sellingCurrency} amount={getUpdatedBid()} cents={false} />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[10px] flex flex-col px-[27.5px]">
              <p className="flex flex-wrap items-center gap-1 text-sm font-normal text-secoundary 2xl:text-base">
                *Are you sure, you want to {auction.auctionType === 'AUCTION' ? 'Place Your Bid' : 'Purchase'}{' '}
                <span className="uppercase">
                  {auction.fuelColor.name} {auction.fuelType.name}
                </span>{' '}
                {auction.fuelPurityLevel.purity} amount {auction.auctionType === 'AUCTION' ? 'at' : 'of'}{' '}
                <span className="text-lg font-bold 2xl:text-xl">
                  <CurrencyFormatter currency={auction.sellingCurrency} amount={getUpdatedTotal()} cents={false} />
                </span>
              </p>
            </div>
            <div className="mb-5 mt-5 flex items-center space-x-2">
              <Label
                htmlFor="anonymous"
                className="flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                <Checkbox checked={anonymous} onCheckedChange={(e) => setAnonymous(!!e)} />
                Keep Identity Anonymous
              </Label>
            </div>
          </>
        ) : placeConfirm ? (
          <>
            <div className="flex w-full flex-col rounded-[10px] border-[1px] border-[#FFFFFF] px-[30px] py-[15px] 2xl:py-[20px]">
              <div className="flex flex-col justify-center">
                <div className="mb-[10px] flex w-full items-center justify-between">
                  <p className="block text-sm text-secoundary 2xl:text-base">Market Price per {fuelWeightUnitText}</p>
                  <p className="block text-sm text-secoundary 2xl:text-base">
                    <CurrencyFormatter currency={auction.sellingCurrency} amount={getUnitPrice()} cents={false} />
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-[10px]">
                  <p className=" block text-sm text-secoundary 2xl:text-base">Bid Price per {fuelWeightUnitText}</p>
                  <p className=" block text-sm text-secoundary 2xl:text-base">
                    <CurrencyFormatter currency={auction.sellingCurrency} amount={getBidPrice()} cents={false} />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-[10px] flex w-full flex-col rounded-lg 2xl:mt-[15px]">
              <div className="flex flex-col justify-around">
                <div className="flex w-full items-center justify-between align-middle">
                  <p className=" block pl-[30px]  text-sm text-secoundary 2xl:text-base">Place New Bid</p>
                  <div className="flex items-center gap-[10px] align-middle">
                    <p className="block w-[200px] text-sm font-bold text-secoundary 2xl:text-base">
                      <div className="border:none rounded-[10px]  pr-[0px]  pt-4 text-right ring-0">
                        <Controller
                          name="bidAmount"
                          control={control}
                          rules={{
                            required: { value: true, message: 'error.required' },
                            min: { value: 0, message: 'error.shouldBePositive' },
                          }}
                          render={({ field, fieldState }) => {
                            return (
                              <>
                                <Input
                                  disabled={confirm}
                                  type="number"
                                  className="text-right [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                                  {...field}
                                  value={field.value}
                                  onChange={(e) =>
                                    e.target.value ? field.onChange(parseFloat(e.target.value)) : field.onChange(null)
                                  }
                                />
                                {fieldState.error && (
                                  <ErrorMessage className="text-right" message={fieldState.error.message as string} />
                                )}
                              </>
                            )
                          }}
                        />
                      </div>
                    </p>
                  </div>
                </div>
                <div className="mt-5 flex items-center space-x-2">
                  <Label
                    htmlFor="anonymous"
                    className="flex gap-[10px] text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    <Checkbox checked={anonymous} onCheckedChange={(e) => setAnonymous(!!e)} />
                    Keep Identity Anonymous
                  </Label>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex w-full flex-col rounded-[10px] border-[1px] border-[#FFFFFF] px-[30px] py-[15px] 2xl:py-[20px]">
              <div className="flex flex-col justify-center">
                <div className="mb-[10px] flex w-full items-center justify-between">
                  <p className="block text-sm text-secoundary 2xl:text-base">Market Price per {fuelWeightUnitText}</p>
                  <p className="block text-sm text-secoundary 2xl:text-base">
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={getUnitPriceCurrent()}
                      cents={false}
                    />
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-[10px]">
                  <p className=" block text-sm text-secoundary 2xl:text-base">Bid Price per {fuelWeightUnitText}</p>
                  <p className=" block text-sm text-secoundary 2xl:text-base">
                    <CurrencyFormatter currency={auction.sellingCurrency} amount={getBidPriceCurrent()} cents={false} />
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-[10px] flex w-full flex-col rounded-lg 2xl:mt-[15px]">
              <div className="flex flex-col justify-around">
                <div className="flex w-full items-center justify-between align-middle">
                  <p className=" block pl-[30px]  text-sm text-secoundary 2xl:text-base">Your Current Bid</p>

                  <div className="flex items-center gap-[10px] align-middle">
                    <p className="text-yellow-300">
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path
                          d="M8.78538 9.63417L13.5967 9.72769L10.3065 6.21558L11.8822 1.66472L7.5244 3.70342L3.69538 0.818034L4.27969 5.57676L0.335208 8.33138L5.05843 9.24558L6.46069 13.8477L8.78538 9.63417Z"
                          fill="#F2C94C"
                        />
                      </svg>
                    </p>
                    <p className="block w-[200px] text-sm font-bold text-secoundary 2xl:text-base">
                      <div className="border:none rounded-[10px] bg-[#F2F2F2] py-4  pr-[30px] text-right ring-0">
                        <CurrencyFormatter
                          currency={auction.sellingCurrency}
                          amount={yourCurrentBid()?.bidAmount}
                          cents={true}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {!confirm && (
          <div className="mt-5 flex w-full flex-col">
            <div className="flex flex-col px-[30px]">
              <div className="mb-[10px] flex w-full items-center justify-between">
                <p className=" block text-sm text-secoundary 2xl:text-base">State Taxes</p>
                <p className=" block text-sm text-secoundary 2xl:text-base">
                  {yourCurrentBid() && (
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={placeConfirm ? getStateTaxAmount() : yourCurrentBid()?.stateTaxAmount}
                      cents={!placeConfirm}
                    />
                  )}
                </p>
              </div>
              <div className="mb-[10px] flex w-full items-center justify-between">
                <p className=" block text-sm text-secoundary 2xl:text-base">City Taxes</p>
                <p className=" block text-sm text-secoundary 2xl:text-base">
                  {yourCurrentBid() && (
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={placeConfirm ? getCityTaxAmount() : yourCurrentBid()?.cityTaxAmount}
                      cents={!placeConfirm}
                    />
                  )}
                </p>
              </div>
              <div className="mb-[10px] flex w-full items-center justify-between">
                <p className=" block text-sm text-secoundary  2xl:text-base">Fuel Fees</p>
                <p className=" block text-sm text-secoundary 2xl:text-base">
                  {yourCurrentBid() && (
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={placeConfirm ? getFuelFeesAmount() : yourCurrentBid()?.fuelFeesAmount}
                      cents={!placeConfirm}
                    />
                  )}
                </p>
              </div>
              <div className="mb-[10px] flex w-full items-center justify-between">
                <p className=" block text-sm text-secoundary  2xl:text-base">Buyer Fees</p>
                <p className=" block text-sm text-secoundary 2xl:text-base">
                  {yourCurrentBid() && (
                    <CurrencyFormatter
                      currency={auction.sellingCurrency}
                      amount={placeConfirm ? getBuyerFeesAmount() : yourCurrentBid()?.buyerFeesAmount}
                      cents={!placeConfirm}
                    />
                  )}
                </p>
              </div>
              <AuctionDialogEstimatedShippingAndShippingRadius auction={auction} />
            </div>
          </div>
        )}
      </div>
      {/* Only show the buttons if the auction is not past auction */}
      {!isPastAuction(auction) && (
        <>
          {!confirm && (
            <>
              <hr className="mr-7 mt-[10px] h-px border-0 bg-[#E0E0E0]" />
              <div className="flex w-full flex-col rounded-lg py-5 pr-7">
                <div className="flex flex-col px-[30px]">
                  <div className="flex w-full items-center justify-between gap-[10px]">
                    <p className=" block text-lg font-bold text-secoundary 2xl:text-xl">Total Payable Amount</p>
                    <p className=" block text-lg font-bold text-secoundary 2xl:text-xl">
                      {yourCurrentBid() && (
                        <CurrencyFormatter
                          currency={auction.sellingCurrency}
                          amount={placeConfirm ? getUpdatedTotal() : yourCurrentBid()?.totalAmount}
                          cents={!placeConfirm}
                        />
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          )}
          {auction.auctionType === 'AUCTION' && (
            <div className="mt-[10px] flex gap-5 pr-7 2xl:mt-5">
              {confirm ? (
                <>
                  <Button variant="secondary" onClick={() => setConfirm(false)}>
                    Back
                  </Button>
                  <Button onClick={performBidMutation}>Confirm Bid</Button>
                </>
              ) : placeConfirm ? (
                <>
                  <Button variant="secondary" onClick={() => setPlaceConfirm(false)}>
                    Cancel
                  </Button>
                  <Button onClick={() => setConfirm(true)}>Place Update Bid</Button>
                </>
              ) : (
                <>
                  <Button variant="secondary" onClick={() => setShowDialog(false)}>
                    Cancel
                  </Button>
                  {highestBid?.id !== myHighestBid?.id && (
                    <Button onClick={() => setPlaceConfirm(true)}>Update Bid</Button>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BuyerAuctionUpdateBidDialog
