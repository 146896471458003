import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { toast } from 'src/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'src/components/ui/button'
import { Input } from 'src/components/ui/input'
import * as React from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import ErrorMessage from 'src/components/ui/error-message'
import { CURRENCIES, currenciesEnum } from 'src/utils/utils'

type IBankAccountForm = {
  accountNumber: string
  country: string
  currency: string
  object: 'bank_account'
  // account_holder_name: string,
  routingNumber?: string
}

const zodValidator = z.object({
  accountNumber: z.string(),
  country: z.string().min(2).max(2),
  currency: z.string(),
  routingNumber: z.string().optional(),
})

const PaymentBankForm = () => {
  const { control, handleSubmit, reset } = useForm<IBankAccountForm>({
    resolver: zodResolver(zodValidator),
  })
  const { t } = useTranslation('translation')
  const queryClient = useQueryClient()

  const { isLoading, data } = useQuery({
    queryKey: ['countries'],
    queryFn: (): Promise<
      AxiosResponse<{
        countries: {
          id: number
          name: string
          iso3: string
          iso2: string
          phoneCode: string
          currency: string
          currencyName: string
          currencySymbol: string
          region: string | null
          regionId: string | null
          subRegion: string | null
        }[]
      }>
    > => {
      const token = localStorage.getItem('token')
      return axios.get('/api/countries', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const externalAccountCreateMutation = useMutation({
    mutationKey: ['externalAccountCreate'],
    mutationFn: (bankAccount: IBankAccountForm): Promise<AxiosResponse<{ message: string }>> => {
      const token = localStorage.getItem('token')
      return axios.post(
        '/api/stripe/connect/externalAccounts',
        {
          bankAccount,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const externalAccountCreate = async (data: IBankAccountForm) => {
    externalAccountCreateMutation.mutate(data, {
      onSuccess: (resp) => {
        toast({
          title: t(resp.data.message),
        })
        reset()
        queryClient.invalidateQueries(['externalAccountsQuery'])
      },
      onError: () => {
        toast({
          title: 'Bank account addition failed please retry.',
        })
        // reset()
      },
    })
  }

  return (
    <form onSubmit={handleSubmit(externalAccountCreate)} className="flex flex-col gap-2.5">
      <div className="flex flex-col gap-[10px]">
        <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Account No.</label>
        <div className="relative flex w-full items-center align-middle">
          <Controller
            name="accountNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Input
                  className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-[10px]">
        <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Country</label>
        <div className="relative flex w-full items-center align-middle">
          <Controller
            name="country"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <div className="mt-2 w-full">
                  <Select
                    name={field.name}
                    value={field.value ? field.value.toString() : ''}
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="" />
                    </SelectTrigger>
                    <SelectContent>
                      {data?.data.countries.map((country) => {
                        return (
                          <SelectItem key={country.id} value={country.iso2}>
                            {country.name}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                  {fieldState.error?.message && <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />}
                </div>
              )
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-[10px]">
        <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Currency</label>
        <div className="relative flex w-full items-center align-middle">
          <Controller
            name="currency"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <div className="w-full">
                  <Select
                    name={field.name}
                    value={field.value ? field.value.toString() : ''}
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="" />
                    </SelectTrigger>
                    <SelectContent>
                      {CURRENCIES.map((currency) => {
                        return (
                          <SelectItem key={currency} value={currency}>
                            {currency}
                          </SelectItem>
                        )
                      })}
                    </SelectContent>
                  </Select>
                  {fieldState.error?.message && <ErrorMessage message={t(fieldState.error.message)} className="mt-2" />}
                </div>
              )
            }}
          />
        </div>
      </div>
      <div className="flex flex-col gap-[10px]">
        <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Routing No.</label>
        <div className="relative flex w-full items-center align-middle">
          <Controller
            name="routingNumber"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <Input
                  className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"
                  {...field}
                  error={fieldState.error}
                />
              )
            }}
          />
        </div>
      </div>
      <Button className="mt-5" type="submit" disabled={externalAccountCreateMutation.isLoading}>
        Add Bank Account
      </Button>
    </form>
  )
}

export default PaymentBankForm
