import { IOrder } from 'src/_models/order.model'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { ICard } from 'src/_models/card.model'
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group'
import { Label } from 'src/components/ui/label'
import * as React from 'react'
import { DialogClose } from 'src/components/ui/dialog'
import { Button } from 'src/components/ui/button'
import { Dispatch, SetStateAction, useState } from 'react'
import { toast } from 'src/components/ui/use-toast'

const BuyerOrderPaymentMethods = ({
  setIsPaymentDialogOpen,
}: {
  order: IOrder
  setIsPaymentDialogOpen: Dispatch<SetStateAction<boolean>>
}) => {
  const queryClient = useQueryClient()
  const [paymentMethodId, setPaymentMethodId] = useState<string>()
  const { isLoading: isLoadingCards, data: dataCards } = useQuery({
    queryKey: ['paymentCardsQuery'],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get<{
        cards: ICard[]
        defaultPayment: ICard
      }>('/api/payments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const defaultCardMutation = useMutation({
    mutationFn: ({ paymentMethodId }: { paymentMethodId: string }) => {
      const token = localStorage.getItem('token')
      return axios.post<{
        cards: ICard[]
        defaultPayment: ICard
      }>(
        `/api/payments/cards/default/${paymentMethodId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const setDefaultCard = () => {
    if (!paymentMethodId) {
      return
    }
    defaultCardMutation.mutate(
      { paymentMethodId },
      {
        onSuccess: () => {
          setIsPaymentDialogOpen(false)
          queryClient.invalidateQueries()
          toast({
            title: 'Success',
            description: 'Default payment method updated.',
          })
        },
        onError: () => {
          toast({
            title: 'Error',
            description: 'Failed to update default payment method.',
          })
        },
      },
    )
  }

  return (
    <div>
      <div className="font-montserrat text-base font-bold">Choose payment method</div>
      {dataCards && (
        <RadioGroup onValueChange={(e) => setPaymentMethodId(e)} className="flex flex-col gap-[10px]">
          {dataCards.data.defaultPayment && (
            <div
              key={dataCards.data.defaultPayment.id}
              className="mt-[10px] flex w-full items-center justify-between align-middle"
            >
              <Label className="flex items-center gap-[10px] py-[10px] align-middle  font-normal 2xl:text-sm">
                <RadioGroupItem id="defaultCard" value={dataCards.data.defaultPayment.id} />
                **** **** **** {dataCards.data.defaultPayment.last4} (Default)
              </Label>
              <div className="">
                Expiry : {dataCards.data.defaultPayment.exp_month}/{dataCards.data.defaultPayment.exp_year}
              </div>
            </div>
          )}
          {dataCards.data.cards.map((card) => (
            <div key={card.id} className="mt-[10px] flex w-full items-center justify-between align-middle">
              <Label className="flex items-center gap-[10px] py-[10px] align-middle  font-normal 2xl:text-sm">
                <RadioGroupItem id="defaultCard" value={card.id} /> **** **** **** {card.last4}
              </Label>
              <div className="">
                Expiry : {card.exp_month}/{card.exp_year}
              </div>
            </div>
          ))}
        </RadioGroup>
      )}
      <div className="mt-2.5 flex gap-5">
        <Button onClick={() => setIsPaymentDialogOpen(false)} variant="secondary">
          Cancel
        </Button>
        <Button disabled={isLoadingCards || !paymentMethodId} onClick={setDefaultCard}>
          Continue
        </Button>
      </div>
    </div>
  )
}

export default BuyerOrderPaymentMethods
