import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group'
import { Label } from 'src/components/ui/label'
import * as React from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { stripePublishableKey } from 'src/config'
import PaymentCardForm from 'src/pages/profile/payment-card-form'
import { ICard } from 'src/_models/card.model'
import { useState } from 'react'
import { toast } from 'src/components/ui/use-toast'
import { useTranslation } from 'react-i18next'
import { useUserStore } from 'src/_store/user.store'
import { IStripeExternalAccount } from 'src/_models/stripe-external-account.model'
import PaymentBankForm from 'src/pages/profile/payment-bank-form'

const stripePromise = loadStripe(stripePublishableKey)

const ProfilePaymentMethods = () => {
  const { t } = useTranslation('translation')
  const [showRightSection, setShowRightSection] = useState<'card' | 'bank' | null>(null)
  const queryClient = useQueryClient()

  const { user } = useUserStore((state) => ({ user: state.user }))

  const { isLoading: isLoading, data: dataCards } = useQuery({
    queryKey: ['paymentCardsQuery'],
    queryFn: (): Promise<
      AxiosResponse<{
        cards: ICard[]
        defaultPayment: ICard
      }>
    > => {
      const token = localStorage.getItem('token')
      return axios.get('/api/payments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const { isLoading: isLoadingConnectBanks, data: dataConnectBanks } = useQuery({
    queryKey: ['externalAccountsQuery'],
    queryFn: (): Promise<
      AxiosResponse<{
        capabilities: { card_payments: 'active' | 'inactive'; transfers: 'active' | 'inactive' }
        future_requirements: {
          alternatives: string[]
          current_deadline: string | null
          currently_due: string | []
          disabled_reason: string | null
          errors: string[]
          eventually_due: string[]
          past_due: string[]
          pending_verification: string[]
        }
        externalAccounts: IStripeExternalAccount[]
        defaultExternalAccount: IStripeExternalAccount | null
      }>
    > => {
      const token = localStorage.getItem('token')
      return axios.get('/api/stripe/connect', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const onboardingMutation = useMutation({
    mutationKey: [''],
    mutationFn: ({ userId }: { userId: number | undefined }) => {
      const token = localStorage.getItem('token')
      return axios.post<{ onboardingUrl: string }>(
        '/api/stripe/connect/onboarding',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    },
  })

  const onboardingAction = () => {
    onboardingMutation.mutate(
      { userId: user?.id },
      {
        onSuccess: (resp) => {
          window.open(resp.data.onboardingUrl, '_blank')
        },
        onError: () => {},
      },
    )
  }

  const deletePaymentMethodMutation = useMutation({
    mutationKey: ['deletePaymentMethodMutation'],
    mutationFn: ({ paymentId }: { paymentId: string }) => {
      const token = localStorage.getItem('token')
      return axios.delete('/api/payments/cards/' + paymentId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const deletePaymentMethod = (paymentId: string) => {
    if (!confirm('Are you sure you want to delete payment method!')) {
      return
    }

    deletePaymentMethodMutation.mutate(
      { paymentId },
      {
        onSuccess: (resp) => {
          toast({
            title: t(resp.data.message),
          })
          queryClient.invalidateQueries(['paymentCardsQuery'])
        },
        onError: () => {
          toast({
            title: 'Card delete failed please retry.',
          })
        },
      },
    )
  }

  const deleteBankAccountMutation = useMutation({
    mutationKey: ['deleteBankAccountMutation'],
    mutationFn: ({ paymentId }: { paymentId: string }) => {
      const token = localStorage.getItem('token')
      return axios.delete('/api/stripe/connect/externalAccounts/' + paymentId, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  })

  const deleteBankAccount = (paymentId: string) => {
    if (!confirm('Are you sure you want to delete payment method!')) {
      return
    }

    deletePaymentMethodMutation.mutate(
      { paymentId },
      {
        onSuccess: (resp) => {
          toast({
            title: t(resp.data.message),
          })
          queryClient.invalidateQueries(['externalAccountsQuery'])
        },
        onError: () => {
          toast({
            title: 'Bank Account delete failed please retry.',
          })
        },
      },
    )
  }

  const stripeConnectApproved = dataConnectBanks?.data?.capabilities?.transfers == 'active'

  return (
    <div className="flex w-full">
      <div className="mx-10 w-full max-w-[500px] 2xl:mx-20 2xl:max-w-[600px]">
        {!isLoadingConnectBanks && (
          <div className="mb-[30px] flex flex-col gap-[10px]">
            {stripeConnectApproved ? (
              <>
                {dataConnectBanks?.data.defaultExternalAccount && (
                  <>
                    <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
                      Default Account Recievable
                    </label>
                    <div className="relative flex w-full items-center align-middle">
                      <Input
                        className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-5 text-left align-middle focus:outline-0 focus:ring-0"
                        value={`**** **** **** ${dataConnectBanks?.data.defaultExternalAccount.last4}`}
                      />
                      <Button
                        className="absolute right-3 w-auto px-5 py-[10px] font-normal"
                        onClick={() => setShowRightSection('bank')}
                      >
                        Add Account
                      </Button>
                    </div>
                  </>
                )}

                <RadioGroup className="flex w-full flex-col gap-[10px]">
                  {dataConnectBanks?.data.externalAccounts &&
                    dataConnectBanks?.data.externalAccounts.map((externalAccount) => (
                      <div key={externalAccount.id} className="flex justify-between">
                        <div className="flex items-center  gap-[10px] align-middle">
                          <RadioGroupItem id="mode" value={externalAccount.id} />

                          <Label className="py-[10px] font-normal 2xl:text-sm">
                            **** **** **** {externalAccount.last4}
                          </Label>
                        </div>
                        <div className="" onClick={() => deleteBankAccount(externalAccount.id)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                              fill="#333333"
                            />
                          </svg>
                        </div>
                      </div>
                    ))}
                </RadioGroup>
              </>
            ) : (
              <Button disabled={onboardingMutation.isLoading} onClick={onboardingAction}>
                Onboard to Payment Provider
              </Button>
            )}
          </div>
        )}

        {!isLoading && dataCards?.data && (
          <>
            <div className="flex flex-col gap-[10px]">
              <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">
                Default Account Payable
              </label>
              <div className="relative flex w-full items-center align-middle">
                <Input
                  readOnly={true}
                  className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-5 text-left align-middle focus:outline-0 focus:ring-0"
                  value={
                    dataCards.data.defaultPayment
                      ? `**** **** **** ${dataCards.data.defaultPayment.last4}           Expiry:${dataCards.data.defaultPayment.exp_month}/${dataCards.data.defaultPayment.exp_year}`
                      : ''
                  }
                />

                <Button
                  className="absolute right-3 w-auto px-5 py-[10px] font-normal"
                  onClick={() => setShowRightSection('card')}
                >
                  Add Account
                </Button>
              </div>
            </div>
            <RadioGroup className="flex flex-col gap-[10px]">
              {dataCards.data.cards.map((card) => (
                <div key={card.id} className="mt-[10px] flex w-full items-center justify-between align-middle">
                  <div className="flex items-center  gap-[10px] align-middle">
                    <RadioGroupItem id="defaultCard" value={card.id} />

                    <Label className="py-[10px] font-normal 2xl:text-sm">**** **** **** {card.last4}</Label>
                  </div>
                  <div className="">
                    Expiry : {card.exp_month}/{card.exp_year}
                  </div>

                  <div className="" onClick={() => deletePaymentMethod(card.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19ZM8 9H16V19H8V9ZM15.5 4L14.5 3H9.5L8.5 4H5V6H19V4H15.5Z"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </div>
              ))}
            </RadioGroup>

            <Button className="mt-[30px] w-fit px-[82px] py-[16px]">Update Details</Button>
          </>
        )}
      </div>
      {showRightSection === 'bank' && (
        <div className="w-full max-w-[417px] 2xl:max-w-[417px]">
          <div className="rounded-5 flex flex-col gap-[30px] bg-white p-[30px]">
            <div className="flex flex-col gap-[10px]">
              <label className="line-height mb-[15px] text-sm font-medium text-secoundary 2xl:text-base">
                Add Bank Account
              </label>
              <PaymentBankForm />
            </div>
          </div>
        </div>
      )}

      {showRightSection === 'card' && (
        <div className="w-full max-w-[417px] 2xl:max-w-[417px]">
          <div className="rounded-5 flex flex-col gap-[30px] bg-white p-[30px]">
            <div className="flex flex-col gap-[10px]">
              <label className="line-height mb-[15px] text-sm font-medium text-secoundary 2xl:text-base">
                Add New Card
              </label>
              <Elements stripe={stripePromise}>
                <PaymentCardForm />
              </Elements>
              {/*  <RadioGroup className="flex flex-col gap-[10px]">*/}
              {/*    <div className="flex items-center gap-[10px]  align-middle ">*/}
              {/*      <RadioGroupItem id="mode" value="Land" />*/}

              {/*      <Label className="py-[10px] font-normal 2xl:text-sm">**** **** **** 2694</Label>*/}
              {/*    </div>*/}
              {/*  </RadioGroup>*/}

              {/*  <RadioGroup className="flex flex-col gap-[10px]">*/}
              {/*    <div className="flex  items-center  gap-[10px] align-middle">*/}
              {/*      <RadioGroupItem id="mode" value="Land" />*/}

              {/*      <Label className="py-[10px] font-normal 2xl:text-sm">**** **** **** 2694</Label>*/}
              {/*    </div>*/}
              {/*  </RadioGroup>*/}
              {/*</div>*/}

              {/*<div className="flex flex-col gap-[10px]">*/}
              {/*  <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Account No.</label>*/}
              {/*  <div className="relative flex w-full items-center align-middle">*/}
              {/*    <Input*/}
              {/*      className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"*/}
              {/*      value="6689 2253 6632"*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*</div>*/}

              {/*<div className="flex gap-3">*/}
              {/*  <div className="flex w-full flex-col gap-[10px]">*/}
              {/*    <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Expiry Month</label>*/}
              {/*    <div className="relative flex w-full items-center align-middle">*/}
              {/*      <Input*/}
              {/*        className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"*/}
              {/*        value="6689 2253 6632"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}

              {/*  <div className="flex w-full flex-col gap-[10px]">*/}
              {/*    <label className="line-height text-sm font-medium text-secoundary 2xl:text-base">Expiry Year</label>*/}
              {/*    <div className="relative flex w-full items-center align-middle">*/}
              {/*      <Input*/}
              {/*        className="text-sm[appearance:textfield] border-1 relative flex w-full items-center border border-primary bg-white  py-[15px]  pr-20 text-left align-middle focus:outline-0 focus:ring-0"*/}
              {/*        value="6689 2253 6632"*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
            </div>
            {/*<Button className="">Done</Button>*/}
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfilePaymentMethods
