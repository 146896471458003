import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IAuction } from 'src/_models/auction.model'
import AuctionCard from 'src/components/auction/auction-card'
import * as React from 'react'
import { useState } from 'react'
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from 'src/components/ui/pagination'
import FuelTradingTopTicker2 from 'src/components/fuel-trading/top-ticker2'
import AuctionFilterBtn from 'src/components/auction/auction-filter-btn'
import { useSearchParams } from 'react-router-dom'

const LiveAuctionsPage = () => {
  const [page, setPage] = useState(1)
  const [searchParams, _] = useSearchParams()

  const params = searchParams.toString()

  const liveAuctionQuery = useQuery({
    queryKey: ['live-auctions', `page-${page}`, 'auctions', params],
    queryFn: () => {
      const token = localStorage.getItem('token')
      return axios.get(`/api/live-auctions?${params}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }) as Promise<AxiosResponse<{ auctions: IAuction[]; totalPages: number }>>
    },
    cacheTime: 60,
  })

  return (
    <>
      <FuelTradingTopTicker2 />

      <section className="w-full">
        <div className="container mx-auto bg-transparent ">
          <div className="mb-[30px] flex w-full justify-between pt-5">
            <h2 className="text-lg font-bold text-secoundary 2xl:text-2xl">Live Auctions</h2>
            <AuctionFilterBtn />
          </div>

          <div className="grid md:grid-cols-3 xl:grid-cols-4 gap-5 2xl:grid-cols-5">
            {liveAuctionQuery.data?.data.auctions &&
              liveAuctionQuery.data?.data.auctions.map((auction) => {
                return (
                  <div className="mb-[15px]" key={`live-auction-${auction.id}`}>
                    <AuctionCard auction={auction} />
                  </div>
                )
              })}
          </div>
        </div>
      </section>
      {liveAuctionQuery.data && liveAuctionQuery.data.data.totalPages > 1 && (
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious onClick={() => setPage((page) => page - 1)} />
            </PaginationItem>
            {Array.from(Array(liveAuctionQuery.data.data.totalPages), (e, i) => {
              return (
                <PaginationItem key={i}>
                  <PaginationLink onClick={() => setPage(i + 1)}>{i + 1}</PaginationLink>
                </PaginationItem>
              )
            })}
            {/*<PaginationItem>*/}
            {/*  <PaginationEllipsis />*/}
            {/*</PaginationItem>*/}
            <PaginationItem>
              <PaginationNext onClick={() => setPage((page) => page + 1)} />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )}
    </>
  )
}

export default LiveAuctionsPage
