import { Dialog, DialogContent, DialogTrigger } from 'src/components/ui/dialog'
import currencyFormatter from 'src/components/ui/currency-formatter'
import { IOrder } from 'src/_models/order.model'
import BuyerOrderPaymentMethods from 'src/components/order/buyer-order-payment-methods'
import { useUserStore } from 'src/_store/user.store'
import { useState } from 'react'

const OrderTotalPayableAmount = ({ order }: { order: IOrder }) => {
  const { user } = useUserStore((store) => ({
    user: store.user,
  }))
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState<boolean>(false)
  const partialPaymentDone =
    [
      'BUYER_PAYMENT_COMPLETED',
      'SHIPPING_CONTRACT_UPLOAD',
      'PRODUCT_SHIPPED',
      'SELLER_PARTIAL_PAYMENT',
      'PRODUCT_DELIVERED',
      'DELIVERY_CONFIRMATION',
      'PRODUCT_CONFIRMATION',
      'SELLER_REMAINDER_PAYMENT_PENDING',
    ].indexOf(order.status) !== -1

  const escrowStatus = ['BUYER_PAYMENT_PENDING', 'BUYER_PAYMENT_COMPLETED', 'SHIPPING_CONTRACT_UPLOAD']

  const partialPaymentStatus = ['PRODUCT_SHIPPED', 'DELIVERY_CONFIRMATION']

  if (user?.userType === 'SUPPLIER') {
    return (
      <div className="flex w-full flex-col rounded-lg py-5">
        <div className="flex flex-col px-[0px]">
          <div className="flex w-full items-center justify-between gap-[10px]">
            <div className="flex items-center gap-[7px]">
              <span className="text-lg font-bold text-secoundary 2xl:text-xl">
                {order.status !== 'COMPLETED' ? 'Total Payout Amount' : 'Total Amount Received'}
              </span>
              <Dialog>
                <DialogTrigger asChild>
                  <span className="cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                      <path
                        d="M11 7.56445H9V5.56445H11M11 15.5645H9V9.56445H11M10 0.564453C8.68678 0.564453 7.38642 0.823111 6.17317 1.32566C4.95991 1.8282 3.85752 2.5648 2.92893 3.49339C1.05357 5.36875 0 7.91229 0 10.5645C0 13.2166 1.05357 15.7602 2.92893 17.6355C3.85752 18.5641 4.95991 19.3007 6.17317 19.8032C7.38642 20.3058 8.68678 20.5645 10 20.5645C12.6522 20.5645 15.1957 19.5109 17.0711 17.6355C18.9464 15.7602 20 13.2166 20 10.5645C20 9.25123 19.7413 7.95087 19.2388 6.73762C18.7362 5.52436 17.9997 4.42197 17.0711 3.49339C16.1425 2.5648 15.0401 1.8282 13.8268 1.32566C12.6136 0.823111 11.3132 0.564453 10 0.564453Z"
                        fill="#333333"
                      />
                    </svg>
                  </span>
                </DialogTrigger>
                <DialogContent className="w-[337px] rounded-[20px] px-5 py-[30px]">
                  <div className="flex flex-col gap-2.5">
                    <div className="flex flex-row">
                      <div className="w-full">Bid Amount</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.bidAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">State Taxes</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.stateTaxAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">City Taxes</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.cityTaxAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">Fuel Fees</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.fuelFeesAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="w-full">Supplier Fees</div>
                      <div className="w-full text-right">
                        -{' '}
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.supplierFeesAmount,
                          cents: true,
                        })}
                      </div>
                    </div>
                    <div className="flex flex-row" hidden={!order.shippingCost}>
                      <div className="w-full">Shipping</div>
                      <div className="w-full text-right">
                        {currencyFormatter({
                          currency: order.auction.sellingCurrency,
                          amount: order.shippingCost,
                          cents: true,
                        })}
                      </div>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              {partialPaymentDone ? (
                escrowStatus.includes(order.status) ? (
                  <span className="text-sm">(Auction Amount in Escrow)</span>
                ) : partialPaymentStatus.includes(order.status) ? (
                  <span className="text-sm">(20% paid to supplier)</span>
                ) : (
                  <span className="text-sm">(100% paid to supplier)</span>
                )
              ) : (
                ''
              )}
            </div>
            <div className=" block text-lg font-bold text-secoundary 2xl:text-xl">
              {currencyFormatter({
                currency: order.auction.sellingCurrency,
                amount:
                  +order.totalAmount +
                  (order.shippingCost ? +order.shippingCost : 0) -
                  (order.supplierFeesAmount ? +order.supplierFeesAmount : 0),
                cents: true,
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex w-full flex-col rounded-lg py-5">
      <div className="flex flex-col px-[0px]">
        <div className="flex w-full items-center justify-between gap-[10px]">
          <div className="flex items-center gap-[7px]">
            <span className="text-lg font-bold text-secoundary 2xl:text-xl">
              {order.status !== 'COMPLETED' ? 'Total Payable Amount' : 'Total Amount Paid'}
            </span>
            <Dialog>
              <DialogTrigger asChild>
                <span className="cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                    <path
                      d="M11 7.56445H9V5.56445H11M11 15.5645H9V9.56445H11M10 0.564453C8.68678 0.564453 7.38642 0.823111 6.17317 1.32566C4.95991 1.8282 3.85752 2.5648 2.92893 3.49339C1.05357 5.36875 0 7.91229 0 10.5645C0 13.2166 1.05357 15.7602 2.92893 17.6355C3.85752 18.5641 4.95991 19.3007 6.17317 19.8032C7.38642 20.3058 8.68678 20.5645 10 20.5645C12.6522 20.5645 15.1957 19.5109 17.0711 17.6355C18.9464 15.7602 20 13.2166 20 10.5645C20 9.25123 19.7413 7.95087 19.2388 6.73762C18.7362 5.52436 17.9997 4.42197 17.0711 3.49339C16.1425 2.5648 15.0401 1.8282 13.8268 1.32566C12.6136 0.823111 11.3132 0.564453 10 0.564453Z"
                      fill="#333333"
                    />
                  </svg>
                </span>
              </DialogTrigger>
              <DialogContent className="w-[337px] rounded-[20px] px-5 py-[30px]">
                <div className="flex flex-col gap-2.5">
                  <div className="flex flex-row">
                    <div className="w-full">Bid Amount</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.bidAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">State Taxes</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.stateTaxAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">City Taxes</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.cityTaxAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">Fuel Fees</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.fuelFeesAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="w-full">Buyer Fees</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.buyerFeesAmount,
                        cents: true,
                      })}
                    </div>
                  </div>
                  <div className="flex flex-row" hidden={!order.shippingCost}>
                    <div className="w-full">Shipping</div>
                    <div className="w-full text-right">
                      {currencyFormatter({
                        currency: order.auction.sellingCurrency,
                        amount: order.shippingCost,
                        cents: true,
                      })}
                    </div>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
            {partialPaymentDone ? (
              escrowStatus.includes(order.status) ? (
                <span className="text-sm">(Auction Amount in Escrow)</span>
              ) : partialPaymentStatus.includes(order.status) ? (
                <span className="text-sm">(20% paid to supplier)</span>
              ) : (
                <span className="text-sm">(100% paid to supplier)</span>
              )
            ) : (
              ''
            )}
          </div>
          <div className=" block text-lg font-bold text-secoundary 2xl:text-xl">
            {currencyFormatter({
              currency: order.auction.sellingCurrency,
              amount: +order.totalAmount + (order.shippingCost ? +order.shippingCost : 0),
              cents: true,
            })}
          </div>
        </div>
        <div>
          {escrowStatus.includes(order.status) && (
            <Dialog open={isPaymentDialogOpen} onOpenChange={(e) => setIsPaymentDialogOpen(e)}>
              <DialogTrigger
                className=" text-[#0077B6] underline font-bold"
                onClick={() => setIsPaymentDialogOpen(true)}
              >
                Edit Payment Method
              </DialogTrigger>
              <DialogContent className="rounded-[20px] px-5 py-[30px]">
                <BuyerOrderPaymentMethods order={order} setIsPaymentDialogOpen={setIsPaymentDialogOpen} />
              </DialogContent>
            </Dialog>
          )}
        </div>
      </div>
    </div>
  )
}

export default OrderTotalPayableAmount
