import * as React from 'react'

import { cn } from 'src/lib/utils'
import { FieldError } from 'react-hook-form'
import ErrorMessage from 'src/components/ui/error-message'
import { useTranslation } from 'react-i18next'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, error, ...props }, ref) => {
  const { t } = useTranslation('translation')
  return (
    <>
      <input
        type={type}
        className={cn(
          'text-gray-900 focus:ring-mild sm:text-sm sm:leading-6 block w-full rounded-md border-[#CCCCCC] p-4 shadow-sm ring-1 ring-inset ring-stepcolor placeholder:text-[#CCCCCC] focus:ring-1 focus:ring-primary',
          className,
          error && 'ring-[#F33000] focus:ring-[#F33000] active:ring-[#F33000]',
        )}
        ref={ref}
        {...props}
      />
      {error?.message && <ErrorMessage message={t(error.message)} className="mt-2" />}
    </>
  )
})
Input.displayName = 'Input'

export { Input }
